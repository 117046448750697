<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat>
      <v-toolbar-title>
        {{ $t("{vm} backups", { vm: details.name }) }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="backups"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        sort-by="row"
        :hide-default-footer="backups.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
      >
        <template v-slot:item.created_time="{ item }">
          <div class="monospace">
            {{ formatDateTime(item.created_time) }}
          </div>
        </template>
        <template v-slot:item.size="{ item }">
          <div class="monospace">
            {{ prettyBytes(item.size) }}
          </div>
        </template>
        <template v-slot:item.success="{ item }">
          <v-icon v-if="item.success" color="success">
            mdi-checkbox-marked-circle
          </v-icon>
          <v-icon v-else color="error">mdi-checkbox-blank-circle</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDateTime from "@/utils/mixins/formatDateTime";
import prettyBytes from "@/utils/mixins/prettyBytes";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "VeeamBackupDetails",
  mixins: [formatDateTime, prettyBytes, isMobile],
  props: {
    backupProductId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    loading: false,
    details: {},
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("ID"),
          value: "backup_id",
        },
        {
          text: this.$i18n.t("created"),
          value: "created_time",
        },
        {
          text: this.$i18n.t("size"),
          value: "size",
        },
        {
          text: this.$i18n.t("message"),
          value: "message",
        },
        {
          text: this.$i18n.t("status"),
          value: "success",
        },
      ];
    },
    backups() {
      if (this.details.backups !== undefined) return this.details.backups;
      return [];
    },
  },
  watch: {
    "$store.state.session": function() {
      this.getDetails();
    },
  },
  methods: {
    getDetails: function() {
      var that = this;
      if (this.backupProductId != null) {
        this.loading = true;
        this.$http
          .get("services/veeam/vm/" + this.backupProductId)
          .then((response) => {
            that.details = response.data;
          })
          .catch((err) => {
            if (err.invalid()) {
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
            }
          })
          .finally(function() {
            that.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<i18n>
{
  "en": {
   "ID": "ID",
   "created": "Created",
   "size": "Size",
   "status": "Status",
   "message": "Message",
   "{vm} backups": "{vm} backups"
  },
  "de": {
   "ID": "ID",
   "created": "angelegt",
   "size": "Grösse",
   "status": "Status",
   "message": "Meldung",
   "{vm} backups": "{vm} Backups"
  }
}
</i18n>
