export default {
  data: () => ({
    isMobile: false,
    mbreakpoint: 600,
  }),
  methods: {
    onResize() {
      if (window != null && window.innerWidth != null)
        this.isMobile = window.innerWidth < this.mbreakpoint;
    },
  },
  beforeDestroy () {
    if (window != null && window.removeEventListener != null)
      window.removeEventListener('resize', this.onResize, { passive: true });
  },
  mounted() {
    if (window != null && window.addEventListener != null)
      window.addEventListener('resize', this.onResize, { passive: true });
    this.onResize();
  },
};
