import store from "@/store";

export default {
  methods: {
    formatDateTime(dateString) {
      var locale = store.state.locale == 'en' ? 'en-GB' : store.state.locale;
      var date = new Date(dateString);
      return date.toLocaleDateString(
        locale, {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
    },
    formatDateTimeISO(dateString) {
      var locale = store.state.locale == 'en' ? 'en-GB' : store.state.locale;
      var date = new Date(dateString);
      return date.toISOString();
    }
  },
  // Usage:
  // import formatDateTime from "@/utils/mixins"
  // export default {
  //   mixins: [formatDateTime],

  // im Template: {{ formatDateTime(date_time_string) }}
};
